// Messages
//––––––––––––––––––––––––––––––––––––––––––––––––––

#toasty {
  position: absolute !important;
  top: 0;
  left: 0;
  -webkit-transform: none !important;
  transform: none !important;
  width: 100%;

  .toast.toasty-theme-material {
    width: 100%;
    height: 64px;
    text-align: center;
    font-size: var(--font-size-15) !important;
    letter-spacing: -0.37px;
    font-family: var(--font-family) !important;
    opacity: 1;

    .toast-text {
      color: var(--topNav-back-color) !important;
    }
  }

  .toast.toasty-theme-material.toasty-type-success {
    background-image: none !important;
    background-color: var(--green-mergetitle) !important;
  }

  #toasty .toast.toasty-theme-material.toasty-type-info {
    background-image: none !important;
    background-color: var(--button-color) !important;
  }

  #toasty .toast.toasty-theme-material.toasty-type-error {
    background-image: none !important;
    background-color: var(--red-color) !important;
  }

  #toasty .toast.toasty-theme-material.toasty-type-warning {
    background-image: none !important;
    background-color: var(--yellow-color) !important;
  }
}

#toasty.toasty-position-top-center {
  top: 0 !important;
  left: 0 !important;
}
