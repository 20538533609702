//$color-unclassified-background: #1f1f1f;
//$color-unclassified-unit-background: #2c2c2c;
//$color-unclassified-unit-background-selected: #4a4a4a; // #242424;
//$color-unclassified-unit-border: #4a4a4a;
//$color-unclassified-foreground: #c7c7c7;
//$color-unclassified-density-fill: #4a4a4a;

$panel-body-padding: 9px;

.stack > * + * {
  margin-top: 9px;

  .stackItem {
    margin-top: 9px;
  }
}

.pGrid {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
}

.pPanel {
  border: 1px solid var(--light-lines-color);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .pPanelHeader {
    font-weight: 400;
    font-size: 15px;
    padding: 6px 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    flex: none;

    &.primary {
      background-color: var(--p-color-blue);
      color: white;
      font-size: 14px;
    }

    &.secondary {
      background-color: var(--p-color-header-bg);
      border-bottom: 1px solid var(--light-lines-color);
      font-family: var(--font-family-condensed);
      font-size: 13px;
      //text-transform: uppercase;
      //letter-spacing: -0.08px;
    }

    .backNav {
      float: left;
      cursor: pointer;
    }
  }

  .pPanelBody {
    padding: $panel-body-padding;
    flex: auto;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: var(--white-color);
    font-size: 12px;
  }
}

.d-contents {
  display: contents !important;
}
