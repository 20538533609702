/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "buttons";
@import "modals";
@import "grids-lists";
@import "links";
@import "alerts";
@import "forms";
@import "dropdowns";
@import "table";
@import "messages";
@import "tooltip";
@import "custom-loaders";
@import 'panels';
@import 'material';
@import 'toast';

:root {
  // Brand Colors
  --p-color-blue: #274a9f;
  --p-color-green: #14803f;
  --p-color-orange: #f25a2c;

  // Colors
  --p-color-header-bg: #EEF0F1;

  --btn-secondary-active-background-color: #545B62;
  --btn-secondary-active-border-color: #4E555B;
  --finished-cluster-paper-color: #EFEFEF;

  --modal-header-bottom-border-color: lightgray;

  --mergeRow-shadow-color: rgba(178, 184, 187, 0.7);
  --mergeBox-shadow-color: rgba(142, 142, 142, 0.5);
  --toggles-shadow-color: rgba(105, 105, 105, 0.5);

  --white-half-color: rgba(255, 255, 255, 0.5);

  --automod-border-color: #dadcdd;
  --deselected-icon-color: #EEF0F1;
  --filtered-term-weight-color: #a1aecd;
  --custom-topic-color: #D8FDE4;

  --add-item-bg: rgba(239, 240, 242, 0.25);

  --p-color-bookmark: #FFB850;

  --p-color-grey-100: #F6F9FA;
  --p-color-grey-200: #F5F5F5;
  --p-color-grey-300: #f0f0f0;
  --p-color-grey-400: #EEF0F1;
  --p-color-grey-500: #E3E0E3;
  --p-color-grey-600: #D8DADC;
  --p-color-grey-700: #D2D2D2;
  --p-color-grey-800: #c7c7c7;
  --p-color-grey-850: #9b9b9b;
  --p-color-grey-900: #6A7883;
  --p-color-grey-1000: #4a4a4a;
  --p-color-grey-1100: #2c2c2c;
  --p-color-grey-1200: #1f1f1f;

  --p-color-border-light: #E3E0E3;
  --p-color-line-light: var(--p-color-border-light);
  --p-color-line-dark: var(--p-color-grey-800);

  // Desaturated versions of the colors
  --labeled-color: #34b536;
  --merged-color: #ee55ff;

  --unclear-color: #ff312d;
  --custom-topic-color: #00d9ff; // #55F0FF;

  --blank-color: #6A7883;

  // Desaturated versions of the colors:
  --labeled-suggested-color: #b9d6b4; // #72ac69;
  --merged-suggested-color: #e8bbec; // #d177d9;
  --unclear-suggested-color: #e9bab3; // #d37566;
  --custom-topic-suggested-color: #a9e1e2;

  --training-accuracy-color: #6cae75;
  --training-loss-color: #ff6745; // #ff7657;
  --validation-accuracy-color: #3dacac; // #f0aa57;
  --validation-loss-color: #ca8e00; // #f0aa57;
  --validation-background-color: #E3E0E3;//


  // Typography
  --font-family: "Barlow";
  --font-family-condensed: "Barlow Condensed";
  --font-family-topnav: "Barlow", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
  --font-dropdown: var(--font-family-topnav);
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-58: 58px;
  --font-size-60: 60px;

  // Font-weight
  --lighter: lighter;
  --bolder: bolder;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-500: 500;

  // Cursors
  --pointer: pointer;

  --m-0: 0;
  --m-2: 2px;
  --m-12: 12px;
  --m-15: 15px;

  --p-0: 0;
  --p-8: 8px;
  --p-15: 15px;
  --p-30: 30px;

  // Width & Height
  --size-100: 100%;
  --topNav-height: 50px;

  //Variables 
  --chart-color: #{$chart-color};
  --p-color-text: #{$p-color-text};
  --p-color-header-bg: #{$p-color-header-bg};
  --topNav-link-color: #{$topNav-link-color};
  --topNav-selected-link-color: #{$topNav-selected-link-color};
  --topNav-back-color: #{$topNav-back-color};
  --sideNav-link-color: #{$sideNav-link-color};
  --sideNav-link-hover-selected-color: #{$sideNav-link-hover-selected-color};
  --sideNav-back-color: #{$sideNav-back-color};
  --sideNav-hover-color: #{$sideNav-hover-color};
  --sideNav-wrapper-background-color: #{$sideNav-wrapper-background-color};
  --sideNav-link: #{$sideNav-link};
  --button-color: #{$button-color};
  --button-primary-hover-color: #{$button-primary-hover-color};
  --button-hover-color: #{$button-hover-color};
  --light-lines-color: #{$light-lines-color};
  --dark-lines-color: #{$dark-lines-color};
  --yellow-color: #{$yellow-color};
  --red-color: #{$red-color};
  --white-color: #{$white-color};
  --divider-color: #{$divider-color};
  --topnav-secondary-shadow-color: #{$topnav-secondary-shadow-color};
  --user-default-image-color: #{$user-default-image-color};
  --model-shadow-color: #{$model-shadow-color};
  --light-grey-color: #{$light-grey-color};
  --grey-color: #{$grey-color};
  --dark-grey-color: #{$dark-grey-color};
  --original-grey-color: #{$original-grey-color};
  --darker-grey-color: #{$darker-grey-color};
  --dodger-blue-color: #{$dodger-blue-color};
  --dark-red-color: #{$dark-red-color};
  --dark-white-color: #{$dark-white-color};
  --link-color: #{$link-color};
  --link-hover-color: #{$link-hover-color};
  --link-disabled-color: #{$link-disabled-color};
  --link-topbar-hover-color: #{$link-topbar-hover-color};
  --form-border-color: #{$form-border-color};
  --platform-bckg-color: #{$platform-bckg-color};
  --tooper-color: #{$tooper-color};
  --tooper-coor-hover: #{$tooper-coor-hover};
  --tooper-bub-color: #{$tooper-bub-color};
  --table-border-color: #{$table-border-color};
  --label-border-bottom-color: #{$label-border-bottom-color};
  --btn-color: #{$btn-color};
  --btn-action-hover-color: #{$btn-action-hover-color};
  --btn-action-disabled-color: #{$btn-action-disabled-color};
  --btn-border-color: #{$btn-border-color};
  --btn-gray-color: #{$btn-gray-color};
  --btn-dots-color: #{$btn-dots-color};
  --btn-secondary-border-color: #{$btn-secondary-border-color};
  --btn-secondary-color: #{$btn-secondary-color};
  --modal-color: #{$modal-color};
  --loader-color: #{$loader-color};
  --modal-backdrop-color: #{$modal-backdrop-color};
  --box-border-color: #{$box-border-color};
  --pretty-color: #{$pretty-color};
  --list-element-color: #{$list-element-color};
  --list-element-filter-color: #{$list-element-filter-color};
  --explorer-hover-color: #{$explorer-hover-color};
  --settings-color: #{$settings-color};
  --paragraph-color: #{$paragraph-color};
  --light-text-color: #{$light-text-color};
  --block-color: #{$block-color};
  --block-unclear-color: #{$block-unclear-color};
  --block-merged-color-to: #{$block-merged-color-to};
  --block-merged-color-from: #{$block-merged-color-from};
  --merge-row-color: #{$merge-row-color};
  --candidate-color: #{$candidate-color};
  --candidate-selected: #{$candidate-selected};
  --dropdown-color: #{$dropdown-color};
  --unitVisibility-color: #{$unitVisibility-color};
  --valid-requiered-color: #{$valid-requiered-color};
  --invalid-required-color: #{$invalid-required-color};
  --anchor-background-color: #{$anchor-background-color};
  --title-background-color: #{$title-background-color};
  --title-color: #{$title-color};
  --doc-content-color: #{$doc-content-color};
  --title-bar-background-color: #{$title-bar-background-color};
  --green-mergetitle: #{$green-mergetitle};
  --green-mergetitle-light: #{$green-mergetitle-light};
  --tooltip-background-color: #{$tooltip-background-color};
  --purple-color: #{$purple-color};
  --colored-bar-outline-color: #{$colored-bar-outline-color};
  --input-placeholder-color: #{$input-placeholder-color};
  --model-failed: #{$model-failed};
  --model-link-support: #{$model-link-support};
  --input-error-color: #{$input-error-color};
  --input-error-background-color: #{$input-error-background-color};
  --disabled-term-color: #{$disabled-term-color};
  --add-item-select: #{$add-item-select};
  --add-item-highlight: #{$add-item-highlight};
}

html, body {
  height: var(--size-100);
  position: relative;
  margin: var(--m-0);
  padding: var(--p-0);
  color: var(--dark-grey-color);
  font-weight: 300;
  font-size: 15px;
  width: var(--size-100);
}

body {
  font-family: var(--font-family);
  font-size: 0.9375rem;
  line-height: 1.45;
  color: var(--light-grey-color);
  background-color: var(--platform-bckg-color);
}

i.icon {
  margin-right: var(--m-2);
}

thead, .labels {
  background-color: rgba(111, 117, 130, 0.7);
  color: var(--white-color);
}

table {
  border-left: 1px solid var(--table-border-color);
  border-right: 1px solid var(--table-border-color);
}

.labels {
  padding: 1rem;
  margin: 0 0 15px 0;
  background-color: transparent;
  color: var(--light-grey-color);
  font-weight: 400;
  border-bottom: 1px solid var(--label-border-bottom-color);
}

.table thead th {
  font-weight: var(--font-weight-500);
}

.paper .table td {
  font-size: 14px;
}

/* icons in the full-block buttons */
.list-icon {
  height: 22px;
  width: 25px;
  vertical-align: middle;
}

.failed + .tooltip .tooltip-inner {
  background-color: var(--red-color);
}

.icon {
  color: var(--topNav-selected-link-color);
}

.img-link {
  cursor: pointer;
}

.topnav-secondary {
  line-height: 50px;
  top: 50px;
  background-color: var(--sideNav-link-hover-selected-color);
  padding: 0 15px;
  //position: fixed;
  right: 0;
  width: 100%;
  margin: 0;
  z-index: 12;
  min-height: 50px;
  align-items: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  flex: none;

  &.with-menu-opened {
    width: calc(100% - 225px);
  }

  .link-title {
    padding: 9px 10px;
  }
}

.chevron-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  border: solid var(--white-color);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
}

.chevron-left {
  transform: rotate(130deg);
  -webkit-transform: rotate(130deg);
  border: solid var(--white-color);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
}

// Margins
.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.me-6 {
  margin-right: 6px;
}

.me-8 {
  margin-right: 8px;
}

.ms-8 {
  margin-left: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.ms-30 {
  margin-left: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.ms-60 {
  margin-left: 60px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-30 {
  margin-bottom: 30px;
}

// Paddings
.p-8 {
  padding: 8px;
}

.p-30 {
  padding: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-30 {
  padding-top: 30px;
}

.pl-8 {
  padding-left: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.p-0 {
  padding: 0;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.br-1 {
  border-right: 1px solid var(--light-lines-color);
}

.bb-1 {
  border-bottom: 1px solid var(--light-lines-color);
}

.bt-1 {
  border-top: 1px solid var(--light-lines-color);
}

.bl-1 {
  border-left: 1px solid var(--light-lines-color);
}

.bg-white {
  background-color: var(--white-color);
}

.text-justify {
  text-align: justify;
}