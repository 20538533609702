.msg-container {
  padding: 30px;
}

.alert-danger {
  a {
    color: var(--dark-red-color);
  }

  p {
    margin-bottom: 0;
  }
}
