mat-slide-toggle.mat-slide-toggle {

  $thumbWH: 10px;

  // not checked
  --slider-outline: 1px solid #6A7883; // var(--p-color-text);
  --slider-background: transparent;
  --slider-thumb-transform: translate3d(2px, 0, 0);
  --slider-thumb-background: #6A7883; // var(--p-color-text);

  &.mat-checked {
    --slider-outline: 1px solid #274a9f; // $p-color-primary;
    --slider-background: #274a9f; // $p-color-primary;
    --slider-thumb-transform: translate3d(22px, 0, 0);
    --slider-thumb-background: white;
  }

  .mat-slide-toggle-bar {
    border: var(--slider-outline) !important;
    background-color: var(--slider-background) !important;

    &:hover .mat-slide-toggle-persistent-ripple {
      opacity: 0.2;
    }
  }

  .mat-slide-toggle-thumb-container {
    width: $thumbWH;
    height: $thumbWH;
    top: 1px;
    transform: var(--slider-thumb-transform) !important;

    .mat-slide-toggle-thumb {
      width: $thumbWH;
      height: $thumbWH;
      box-shadow: none;
      background: var(--slider-thumb-background) !important;
    }

    .mat-ripple-element.mat-slide-toggle-persistent-ripple {
      transform: translate(-50%, 0);
    }
  }

}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
