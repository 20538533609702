// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  cursor: pointer; /* not sure where this is over written ... but its dumb */
}

a.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
}

a, a:link, a:visited, a:focus, a:active {
  color: var(--button-color);
  font-weight: 400;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--button-primary-hover-color);
}

a.link-title {
  text-transform: uppercase;
  text-decoration: none;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-18);
  line-height: 19px;
  font-weight: var(--font-weight-500);
  color: var(--topNav-selected-link-color);
}
