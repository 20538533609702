// Modals
//––––––––––––––––––––––––––––––––––––––––––––––––––

.modal-backdrop.show {
  background-color: rgba(0, 0, 0, 0.15);
}

.modal {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.32);
  box-shadow: 0 2px 20px 0 var(--model-shadow-color);

  &.modal-xs {
    .modal-dialog {
      max-width: 600px !important;
    }

    .modal-content {
      padding: 0;
    }
  }

  &.modal-gt {

    .modal-dialog {
      max-width: 930px !important;
    }
  }

  &.modal-flush {
    //background-color: red;

    .modal-dialog {
      max-width: 600px !important;
    }

    .modal-content {
      border-radius: 0;
      padding: 0;
    }
  }

  .modal-content {
    padding: 0 15px;
    -webkit-box-shadow: 0 2px 20px 0 var(--model-shadow-color);
    -moz-box-shadow: 0 2px 20px 0 var(--model-shadow-color);
    box-shadow: 0 2px 20px 0 var(--model-shadow-color);
    border: none !important;

    .modal-header {
      min-height: 76px;
      border-bottom: 1px solid var(--light-lines-color);
      padding: 20px;

      .modal-title {
        color: var(--light-grey-color);
        font-family: var(--font-family-condensed);
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-500);
        letter-spacing: -0.1px;
        line-height: 29px;
        text-transform: uppercase;
        margin: 0 auto;
      }
    }

    .modal-body {
      padding: 50px 85px;
    }

    .form-group {
      margin: 0 auto 1rem auto;
    }
  }
}

// This will make Angular Material CDK Overlays appear above Bootstrap modals:
.cdk-overlay-container {
  z-index: 2000;
}
