$p-gridList-row-height: 62px;

.gridList {
  width: 100%;
  display: grid;
  position: relative;
  flex: auto;
  overflow-y: auto;


  grid-template-rows: 3.5rem; // header
  grid-auto-rows: $p-gridList-row-height; // body

  cdk-header-row, cdk-row {
    display: contents;

    cdk-header-cell, cdk-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 1em;

      &:first-child {
        padding-left: 3rem;
        font-weight: var(--font-weight-500);
      }

      &:last-child {
        padding-right: 3rem;
      }
    }
  }

  cdk-header-cell {
    border-bottom: 0.5px solid var(--light-lines-color);

    height: 3.5rem !important;
    background-color: var(--sideNav-link-hover-selected-color);
    font-size: var(--font-size-15);
    z-index: 11;

    vertical-align: bottom;
    align-self: end;
    padding: 0.75rem 0 0.75rem 0;
    text-align: left;

    color: var(--p-color-text);
    font-family: var(--font-family-condensed);
    font-weight: var(--font-weight-500);
    line-height: 18px;
    text-transform: uppercase;
  }

  cdk-cell {
    background-color: var(--white-color);
    font-size: var(--font-size-13);

    line-height: $p-gridList-row-height;
    border-bottom: 1px solid var(--light-lines-color);

    vertical-align: middle;

    a {
      font-size: var(--font-size-15);
    }

    .action-link {
      color: var(--topNav-selected-link-color);
      text-transform: uppercase;
      font-weight: var(--font-weight-500);
      letter-spacing: -0.09px;
      line-height: 18px;
      font-family: var(--font-family-condensed);
      font-size: var(--font-size-15);

      &:hover {
        color: var(--button-primary-hover-color);
      }

      &.failed {
        color: var(--dark-red-color) !important;
      }

      &.fatal {
        color: var(--dark-red-color) !important;
      }
    }

    .btn {
      padding-bottom: 0;
      padding-top: 0;
      box-shadow: none !important;

      &:focus {
        box-shadow: none !important;
      }

      &.downloadBtn {
        padding: 0;
        margin: 0 15px;
      }
    }
  }
}

.datatable-load-blocker {
  cursor: progress;
  cdk-table {
    pointer-events: none;
  }
}

$font-size: 12px;

@mixin tableGridHeaderCell {
  border-bottom: 0.5px solid var(--light-lines-color);

  background-color: var(--sideNav-link-hover-selected-color);
  z-index: 11;

  color: var(--p-color-text);
  font-family: var(--font-family-condensed);
  font-weight: var(--font-weight-500);

  font-size: $font-size;
  padding: 6px 9px;

  position: sticky;
  top: 0;

  white-space: nowrap;

  &:first-child {
    padding-left: 9px;
  }

  &:last-child {
    padding-right: 9px;
  }
}

// Used in tables
.tableGrid {
  $row-height: 40px;
  height: 100%;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: max-content; // Should be overridden by instantiator!
  grid-template-rows: max-content;
  grid-auto-rows: max-content;
  align-items: center;
  overflow-y: auto;

  cdk-header-row, cdk-row {
    display: contents;
  }

  cdk-header-cell {
    @include tableGridHeaderCell;
  }

  cdk-row {
    cdk-cell {
      font-size: $font-size;
      padding-left: 9px;
      padding-right: 9px;

      line-height: unset;
      // height: $row-height;
      min-height: $row-height;
      border: none;
      align-self: stretch;
  
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      border-bottom: 1px solid var(--light-lines-color);

      > * {
        display: block;
      }
  
      &:first-child {
        padding-left: 9px;
      }
    }

  }
}

.numberCell {
  text-align: right;
  font-variant-numeric: tabular-nums;
}
.tableWrapper {
  display: flex;
  flex: auto;
  overflow: hidden;
  flex-direction: column;
}
  
.paginated-table {
  cdk-header-cell {
    position: sticky;
    top: 0;
  }
}

.paginator-visible {
  padding-bottom: 56px;
}

mat-paginator {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px #cacaca80;
}