// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.btn {
  border-radius: 17.5px;
  height: 30px;
  padding: 0 30px;
  text-transform: uppercase;
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-16);
  text-align: center;
  line-height: 22px;
  letter-spacing: -0.09px;
  font-weight: 400;

  &:disabled, &.disabled {
    cursor: default !important;
  }
}

.btn-primary {
  background-color: var(--button-color);
  color: var(--white-color);
  border: none;

  &:hover, &:focus {
    background-color: var(--button-primary-hover-color);
    border: none;
    box-shadow: none;
  }
}

.btn-secondary {
  background-color: var(--dark-lines-color);
  color: var(--white-color);
  border: none;

  &:hover, &:focus {
    background-color: var(--button-hover-color);
    border: none;
  }
}

.btn-tertiary {
  background-color: var(--white-color);
  color: var(--dark-lines-color);
  border: 1px solid var(--dark-lines-color);

  &:hover, &:focus {
    background-color: var(--button-hover-color);
    color: var(--white-color);
  }
}

.btn-primary:disabled {
  background-color: var(--dark-lines-color);
  color: var(--white-color);
  border: none;

  &:hover, &:focus {
    background-color: var(--dark-lines-color);
    border: none;
  }
}

.btn-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  padding: 0.55rem 0;
}

.btn-link {
  padding: 5px 0;
  color: var(--link-color);
  border: none;
  text-decoration: none;
  background-color: transparent;

  &:hover, &:focus {
    padding: 5px 0;
    color: var(--button-primary-hover-color) !important;
    text-decoration: none;
    border: none;
  }
}

.btn-link.disabled {
  color: var(--link-disabled-color);
  text-decoration: none;
  pointer-events: none;
}

.btn-action {
  color: var(--btn-color);
  background-color: var(--link-color);
  border-color: var(--link-color);
  border-radius: 0;

  &:hover {
    color: var(--btn-color);
    background-color: var(--btn-action-hover-color);
    border-color: var(--btn-action-hover-color);
  }
}

.btn-action:focus, .btn-action.focus {
  color: var(--white-color);
}

.btn-action.disabled, .btn-action:disabled {
  background-color: var(--btn-action-disabled-color);
  border-color: var(--btn-action-disabled-color);
}

.btn-action:active, .btn-action.active,
.show > .btn-action.dropdown-toggle {
  color: var(--btn-color);
  background-color: var(--btn-action-hover-color);
  background-image: none;
  border-color: var(--btn-action-hover-color);
}

/* btn-transparent */
.btn-trasnparent {
  color: var(--light-grey-color);
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;

  &:hover {
    color: var(--light-grey-color);
    background-color: transparent;
    border-color: var(--link-color);
  }
}

.btn-trasnparent:focus, .btn-trasnparent.focus {
  color: var(--white-color);
}

.btn-trasnparent.disabled, .btn-trasnparent:disabled {
  background-color: var(--btn-action-disabled-color);
  border-color: var(--btn-action-disabled-color);
}

.btn-trasnparent:active, .btn-trasnparent.active,
.show > .btn-trasnparent.dropdown-toggle {
  color: var(--light-grey-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--link-color);
}

/* btn-gray */
.btn-gray {
  color: var(--btn-color);
  background-color: var(--grey-color);
  border-color: var(--grey-color);

  &:hover {
    color: var(--btn-color);
    background-color: var(--btn-gray-color);
    border-color: var(--btn-gray-color);
  }
}

.btn-gray:focus, .btn-gray.focus {
  color: var(--white-color);
}

.btn-gray.disabled, .btn-gray:disabled {
  background-color: var(--btn-gray-color);
  border-color: var(--btn-gray-color);
}

.btn-gray:active, .btn-gray.active,
.show > .btn-gray.dropdown-toggle {
  color: var(--btn-color);
  background-color: var(--grey-color);
  background-image: none;
  border-color: var(--grey-color);
}

button, .btn {
  cursor: pointer;
}

.btn-outline-gray {
  color: var(--light-grey-color);
  background-color: transparent;
  border-color: var(--form-border-color);
  border-radius: 0;
  font-weight: 300;

  &:hover {
    color: var(--light-grey-color);
    background-color: transparent;
    border-color: var(--form-border-color);
  }
}

.btn-outline-gray:focus, .btn-outline-gray.focus {
  color: var(--light-grey-color);
}

.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  //background-color: var(--btn-action-disabled-color);
  //border-color: var(--btn-action-disabled-color);
}

.btn-outline-gray:active, .btn-outline-gray.active,
.show > .btn-outline-gray.dropdown-toggle {
  color: var(--light-grey-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--form-border-color);
}

.btn-outline {
  color: var(--button-color);
  background-color: transparent;
  border-color: var(--button-color);
  border-radius: 0;
  font-weight: 300;

  &:hover {
    color: var(--button-color);
    background-color: transparent;
    border-color: var(--button-color);
  }
}

.btn-outline:focus, .btn-outline.focus {
  color: var(--button-color);
}

.btn-outline.disabled, .btn-outline:disabled {
  //background-color: var(--btn-action-disabled-color);
  //border-color: var(--btn-action-disabled-color);
}

.btn-outline:active, .btn-outline.active,
.show > .btn-outline.dropdown-toggle {
  color: var(--button-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--button-color);
}

.btn.isEmpty {
  color: var(--form-border-color);
}


/*
 *
 * BUTTON GROUP
 *
 */

.btn-group {

  > .btn,
  > .btn-group > .btn {
    --bs-btn-active-border-color: rgba(0,0,0,0);
    border-width: 0;
    margin-bottom: 0; // Override default `<label>` value

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

}

