// Grids & Lists
//––––––––––––––––––––––––––––––––––––––––––––––––––

#content {
  margin: 0;
  padding-top: var(--topNav-height);
}

#platform {
  height: 100vh;
  min-height: 100%;
  color: var(--light-grey-color);
  margin: 0;
  font-weight: 300;

  #content {
    height: 100vh;
  }
}

.paper {
  background-color: var(--white-color);
  border-radius: 0;

  + .paper {
    margin-top: 30px;
  }

  > .labels {
    margin-left: 0;
    margin-right: 0;
  }

  .table {
    margin-bottom: 0;
  }
}

.topper {
  color: var(--tooper-color);
  margin-bottom: 15px;

  a {
    color: var(--tooper-color);
    margin-bottom: 15px;

    &:hover {
      color: var(--tooper-coor-hover);
      text-decoration: none;
    }
  }

  h2 {
    font-size: 22px;
    display: inline-block;
    font-weight: 300;
  }

  .sub {
    color: var(--tooper-bub-color);
    font-size: 18px;
    font-weight: 300;
  }
}

.loader .ubq-loader {
  background-color: var(--loader-color);
  border-color: var(--loader-color);
  border-radius: 0;
}

.msg-container {
  padding: 30px;
}

.onDeleteLens h5 {
  width: 100%;
  display: inline;
  text-align: center;
  font-weight: 300;
}

#chart-element {
  min-height: 360px;
  opacity: 1;
}

/* list containers */
.list-container {
  background-color: transparent;
  height: 60px;

  .list-row {
    margin: 0;
    height: 60px;
    line-height: 60px;
    color: var(--light-grey-color);
    font-size: 15px;

    &:not(.header) {
      margin: 0 0 15px 0;
      background-color: var(--white-color);
    }

    &.header {
      margin-top: 45px;
      line-height: normal;
      height: 1em;
      font-weight: 500;
      font-size: 12px;
    }

    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
      border: 0;
      float: left;
    }

    span:nth-child(1) {
      padding-left: 15px;
      width: 10%;
    }

    span:nth-child(2) {
      width: 35%;
    }

    span:nth-child(3) {
      width: 20%;
    }

    span:nth-child(4) {
      width: 20%;
      font-size: 12px;
    }

    span:nth-child(5) {
      width: 15%;
      border-left: 1px solid var(--box-border-color);
    }

    i {
      display: inline-flex;
      vertical-align: middle;
      color: var(--link-color);
    }
  }
}

#limitations {
  p {
    font-size: 14px;
    margin: 0;
  }

  .title {
    text-transform: lowercase;
    font-weight: bold;
  }

  p.title {
    padding-top: 8px;
  }
}

.data-pretty-print {
  font-size: 10px;
  color: var(--light-grey-color);
  padding: 10px 0 25px 7px;
}

.mergeRow {
  padding: 16px 20px;
  background: var(--title-bar-background-color);
  -webkit-box-shadow: 0 2px 2px 1px var(--mergeRow-shadow-color);
  -moz-box-shadow: 0 2px 2px 1px var(--mergeRow-shadow-color);
  box-shadow: 0 2px 2px 1px var(--mergeRow-shadow-color);

  h1 {
    font-family: var(--font-family-condensed);
    font-size: var(--font-size-24);
  }
}

.toggle-hide:hover i {
  cursor: pointer;

  &.fa-eye {
    color: var(--valid-requiered-color);
  }

  &.fa-eye-slash {
    color: var(--red-color);
  }
}
